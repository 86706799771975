import i18n from '@/services/lang';

export const tableColumns = [
	{
		model: 'Remark',
		i18n: 'remark2317',
		sortable: true,
	},
	{
		model: 'ContactSalutationId',
		i18n: 'salutation',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'ContactName',
		i18n: 'name',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'ContactEmailLogin',
		i18n: 'e-mail',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'ContactPhones',
		i18n: 'phonecontact',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'ContactType',
		i18n: 'type2236',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'RoleId',
		i18n: 'role2695',
		hidden: true,
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'ContactIsSubscribed',
		i18n: 'sending',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'ContactIsWrong',
		i18n: 'status',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
		filter: 'country',
	},
];
