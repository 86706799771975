<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:tableName="$options.name"
		:ref="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		:customFilterDropdowns="filterDropdowns"
		:customHeadingTranslations="{ RemarkOwner: $t('colleague') }"
		headerTitle="contacts2233"
		tooltipMsg="manualforcontac"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'ContactId', ascending: 0 }"
		modelId="ContactId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="ContactsTable"
	>
		<template #filter__Remark>
			<div class="d-flex flex-row align-items-center">
				<b-form-select @change="handleFilter()" v-model="filterModel.Remark" :options="filterDropdowns.Remark"></b-form-select>
				<label>&nbsp;&nbsp;</label>
				<b-form-select
					@change="handleFilter()"
					v-model="filterModel.RemarkOwner"
					:options="filterDropdowns.RemarkOwner"
				></b-form-select>
			</div>
		</template>

		<template #Remark="list">
			<div class="d-flex">
				<remark-indicate
					:entityId="list.row.ContactId"
					:entityTypeId="1"
					:refreshMethod="tableGetData"
					:defaultRemark="list.row.Remark"
					:administratorList="administratorList"
				></remark-indicate>
			</div>
		</template>

		<!-- <template #ContactIsWrong="list">{{ formatCreatedBy(list.row.ContactIsWrong) }}</template> -->
		<template #ContactType="list">{{ formatContactType(list.row.ContactType) }}</template>

		<template #RoleId="list">{{ formatRole(list.row.RoleId) }}</template>

		<template #ContactIsSubscribed="list">
			<boolean-display :boolValue="Boolean(list.row.ContactIsSubscribed)"></boolean-display>
		</template>

		<template #ContactIsWrong="list">
			<div class="d-flex flex-row align-items-center justify-content-start">
				<div>
					<span v-if="list.row.ContactIsWrong + list.row.ContactIsBadEmail + list.row.ContactIsTotalMagor == 0">-</span>
					<b-badge v-if="Boolean(list.row.ContactIsWrong)" class="contactBadsItem" variant="warning">{{
						$t("isn'tlandlord")
					}}</b-badge>
					<b-badge v-if="Boolean(list.row.ContactIsBadEmail)" class="contactBadsItem" variant="danger">{{
						$t('wrongmail')
					}}</b-badge>
					<b-badge v-if="Boolean(list.row.ContactIsTotalMagor)" class="contactBadsItem" variant="primary">{{
						$t("won'tcooperate")
					}}</b-badge>
				</div>
				<note-display :value="list.row.ContactNote" v-if="list.row.ContactNote" class="ml-2" />
			</div>
		</template>
		<template #ContactSalutationId="list">{{ formatSalutation(list.row) }}</template>

		<template #ContactName="list">{{ formatFullName(list.row) }}</template>

		<template #ContactEmailLogin="list">{{ formatContactEmails(list.row) }}</template>

		<template #ContactPhones="list">
			<div class="contact-phones-wrapper">{{ formatPhones(list.row) }}</div>
		</template>

		<template #CountryId="list">
			<country-flag :countryId="list.row.CountryId"></country-flag>
		</template>
		<template #customActions="list">
			<email-send-dropdown
				:buttonText="$t('sendanoffer')"
				:pesLogTypeId="pesLogTypeId"
				:offersList="emailsList"
				:entityId="+list.row.ContactId"
				:withTable="false"
			></email-send-dropdown>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import BooleanDisplay from '@/components/common/boolean-display';
import NoteDisplay from '@/components/common/note-display';
import serviceEnums from '@/services/service/enums.service';
import apiUser from '../../services/api/user.api';
import { cloneDeep } from 'lodash';
import { setDropdownOptions } from '@/components/general/utils';
import utils from '../../services/helpers/utils';
import serviceCommon from '../../services/service/common.service';
import servicePortalEmail from '@/modules/portal-emails/portal-email.service';
import enums from '@/services/helpers/enums';

import RemarkIndicate from '../../components/table/remark-indicate';
import apiDictionary from '../../services/api/dictionary.api';

import { tableColumns } from './contacts.table-data';

export default {
	name: 'ContactsTable',

	components: {
		TablePage,
		BooleanDisplay,
		NoteDisplay,
		RemarkIndicate,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'ContactListItem',
			contactBads: serviceEnums.getEnumDict('contactBads'),
			contactTypeDict: serviceEnums.getEnumDict('contactType'),
			roleDict: serviceEnums.getEnumDict('memberRole'),
			filterDropdowns: {
				Remark: [],
				RemarkOwner: [],
			},
			filters: {
				Remark: null,
				RemarkOwner: null,
			},
			pesLogTypeId: enums.sendLogsTypeEnum.Contact,
			useQuickFilter: true,
		};
	},

	async created() {
		// this.columns = await this.prepareColumns();
		// this.getAdminAndRemarkList();

		const emailIds = '61,62';

		const [columns, emailSubjects, _] = await Promise.all([
			this.prepareColumns(),
			servicePortalEmail.getEmailSubjectsByIds(emailIds).then((res) => res.data),
			this.getAdminAndRemarkList(),
		]);

		this.columns = columns;
		this.emailsList = emailSubjects;
	},

	methods: {
		formatSalutation(row) {
			let salutation =
				this.salutationDict && row.ContactSalutationId && row.ContactSalutationId > 0
					? this.salutationDict[row.ContactSalutationId].Text
					: '';
			let salutationEmail =
				this.salutationDict && row.ContactSalutationEmailId && row.ContactSalutationEmailId > 0
					? this.salutationDict[row.ContactSalutationEmailId].Text
					: '';

			if (salutation != '' && salutationEmail != '') {
				return salutation + ' / ' + salutationEmail;
			} else if (salutation != '') {
				return salutation;
			} else if (salutationEmail != '') {
				return salutationEmail;
			}
		},

		formatFullName(row) {
			if (row.ContactSurname != '' || row.ContactSurnameEmail != '') {
				return row.ContactName + ' ' + row.ContactSurname + ' / ' + row.ContactSurnameEmail;
			} else {
				return row.ContactName;
			}
		},

		formatContactEmails(row) {
			if (!row.ContactEmailLogin) {
				return row.ContactEmails.split(';')[0];
			}

			return row.ContactEmailLogin;
		},

		formatPhones(row) {
			let allPhones = '';
			if (row.UserPhones) {
				allPhones = row.UserPhones + '\n';
			}
			allPhones += row.ContactPhones.split(';').join('\n');

			return allPhones;
		},

		formatCreatedBy(createdBy) {
			return this.contactBads[createdBy].Text;
		},

		formatContactType(typeId) {
			return this.contactTypeDict[typeId].Text;
		},

		formatRole(roleId) {
			if (roleId > 0) {
				return this.roleDict[roleId].Text;
			} else {
				return '-';
			}
		},

		redirectToDetail(id = 0) {
			this.$router.push({
				name: links.contactDetailName,
				params: { ContactId: id },
			});
		},

		getSalutationEnum(i18n) {
			return apiDictionary
				.getList('Salutation')
				.then((resp) => {
					let locale = this.$store.state.lang.locale;
					let responseDict = utils.convertItems(resp.data, function (item) {
						let val =
							locale === 'cs-CZ' ? item.SalutationNameCz : locale === 'sk-SK' ? item.SalutationNameSk : item.SalutationNameEn;
						return {
							Id: item.SalutationId,
							Text: val,
						};
					});
					let responseSelector = utils.convertItems(
						resp.data,
						function (item) {
							let val =
								locale === 'cs-CZ' ? item.SalutationNameCz : locale === 'sk-SK' ? item.SalutationNameSk : item.SalutationNameEn;
							return {
								value: item.SalutationId,
								text: val,
							};
						},
						{ value: null, text: '' }
					);
					responseSelector.push({
						value: -1,
						text: i18n.t('withoutsalutati'),
					});

					return { responseDict, responseSelector };
				})
				.then((resp) => {
					let dict = utils.arrayToDict(resp.responseDict, 'Id', [{ Id: 0, Text: '' }]);
					let czechEmailSalutationId = 3;
					let selector = resp.responseSelector.filter((item) => item.value !== czechEmailSalutationId);
					return { dict, selector };
				});
		},

		async getAdminAndRemarkList() {
			let admins = await apiUser.getAdministratorList();
			this.administratorList = cloneDeep(admins.data);
			this.administratorList.unshift({ id: null, name: this.$i18n.t('unassigned') });
			admins.data.unshift({ id: -1, name: this.$i18n.t('unassigned') });
			const RemarkOwner = utils.convertItems(
				admins.data,
				function (item) {
					return { value: item.id, text: item.name };
				},
				{ value: null, text: '' }
			);
			const Remark = serviceEnums.getEnumForDropdown('remark', true);

			this.filterDropdowns = {
				Remark,
				RemarkOwner,
			};
		},

		async getEmailSubjects() {
			const emailIds = '7,50,51,59,60,63,30,29,28,1622';
			return servicePortalEmail.getEmailSubjectsByIds(emailIds).then((resp) => resp.data);
		},

		async prepareColumns() {
			let columns = tableColumns;

			columns = prepareCreatedby(columns);
			columns = prepareContactType(columns);
			columns = prepareRole(columns);
			columns = await prepareSalutation.bind(this)(columns);

			return columns;

			async function prepareSalutation(columns) {
				const resp = await this.getSalutationEnum(this.$i18n);
				this.salutationDict = resp.dict;
				const values = resp.selector;

				return setDropdownOptions(columns, 'ContactSalutationId', values);
			}

			function prepareRole(columns) {
				const values = serviceEnums.getEnumForDropdown('memberRole', true);

				return setDropdownOptions(columns, 'RoleId', values);
			}

			function prepareContactType(columns) {
				const values = serviceEnums.getEnumForDropdown('contactType', true);

				return setDropdownOptions(columns, 'ContactType', values);
			}

			function prepareCreatedby(columns) {
				const values = serviceEnums.getEnumForDropdown('contactBads', true);

				return setDropdownOptions(columns, 'ContactIsWrong', values);
			}
		},
	},
};
</script>

<style lang="scss">
.ContactsTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row > th:nth-child(2) {
		min-width: 85px;
		width: 85px;
	}
	.VueTables__filters-row > th:nth-child(3) {
		min-width: 75px;
		width: 75px;
	}
	.VueTables__filters-row > th:nth-child(8) {
		min-width: 75px;
		width: 75px;
	}
	.VueTables__filters-row > th:nth-child(9) {
		min-width: 75px;
		width: 75px;
	}
	.VueTables__filters-row > th:nth-child(10) {
		min-width: 75px;
		width: 75px;
	}

	tbody {
		.contact-phones-wrapper {
			white-space: pre-wrap;
		}
	}
}
</style>
