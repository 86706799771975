var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: _vm.$options.name,
        staticClass: "ContactsTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          customFilterDropdowns: _vm.filterDropdowns,
          customHeadingTranslations: { RemarkOwner: _vm.$t("colleague") },
          headerTitle: "contacts2233",
          tooltipMsg: "manualforcontac",
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "ContactId", ascending: 0 },
          modelId: "ContactId",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "filter__Remark",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row align-items-center" },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.filterDropdowns.Remark },
                        on: {
                          change: function ($event) {
                            return _vm.handleFilter()
                          },
                        },
                        model: {
                          value: _vm.filterModel.Remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterModel, "Remark", $$v)
                          },
                          expression: "filterModel.Remark",
                        },
                      }),
                      _c("label", [_vm._v("  ")]),
                      _c("b-form-select", {
                        attrs: { options: _vm.filterDropdowns.RemarkOwner },
                        on: {
                          change: function ($event) {
                            return _vm.handleFilter()
                          },
                        },
                        model: {
                          value: _vm.filterModel.RemarkOwner,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterModel, "RemarkOwner", $$v)
                          },
                          expression: "filterModel.RemarkOwner",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "Remark",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("remark-indicate", {
                        attrs: {
                          entityId: list.row.ContactId,
                          entityTypeId: 1,
                          refreshMethod: _vm.tableGetData,
                          defaultRemark: list.row.Remark,
                          administratorList: _vm.administratorList,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "ContactType",
              fn: function (list) {
                return [
                  _vm._v(_vm._s(_vm.formatContactType(list.row.ContactType))),
                ]
              },
            },
            {
              key: "RoleId",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm.formatRole(list.row.RoleId)))]
              },
            },
            {
              key: "ContactIsSubscribed",
              fn: function (list) {
                return [
                  _c("boolean-display", {
                    attrs: { boolValue: Boolean(list.row.ContactIsSubscribed) },
                  }),
                ]
              },
            },
            {
              key: "ContactIsWrong",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-start",
                    },
                    [
                      _c(
                        "div",
                        [
                          list.row.ContactIsWrong +
                            list.row.ContactIsBadEmail +
                            list.row.ContactIsTotalMagor ==
                          0
                            ? _c("span", [_vm._v("-")])
                            : _vm._e(),
                          Boolean(list.row.ContactIsWrong)
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "contactBadsItem",
                                  attrs: { variant: "warning" },
                                },
                                [_vm._v(_vm._s(_vm.$t("isn'tlandlord")))]
                              )
                            : _vm._e(),
                          Boolean(list.row.ContactIsBadEmail)
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "contactBadsItem",
                                  attrs: { variant: "danger" },
                                },
                                [_vm._v(_vm._s(_vm.$t("wrongmail")))]
                              )
                            : _vm._e(),
                          Boolean(list.row.ContactIsTotalMagor)
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "contactBadsItem",
                                  attrs: { variant: "primary" },
                                },
                                [_vm._v(_vm._s(_vm.$t("won'tcooperate")))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      list.row.ContactNote
                        ? _c("note-display", {
                            staticClass: "ml-2",
                            attrs: { value: list.row.ContactNote },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "ContactSalutationId",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm.formatSalutation(list.row)))]
              },
            },
            {
              key: "ContactName",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm.formatFullName(list.row)))]
              },
            },
            {
              key: "ContactEmailLogin",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm.formatContactEmails(list.row)))]
              },
            },
            {
              key: "ContactPhones",
              fn: function (list) {
                return [
                  _c("div", { staticClass: "contact-phones-wrapper" }, [
                    _vm._v(_vm._s(_vm.formatPhones(list.row))),
                  ]),
                ]
              },
            },
            {
              key: "CountryId",
              fn: function (list) {
                return [
                  _c("country-flag", {
                    attrs: { countryId: list.row.CountryId },
                  }),
                ]
              },
            },
            {
              key: "customActions",
              fn: function (list) {
                return [
                  _c("email-send-dropdown", {
                    attrs: {
                      buttonText: _vm.$t("sendanoffer"),
                      pesLogTypeId: _vm.pesLogTypeId,
                      offersList: _vm.emailsList,
                      entityId: +list.row.ContactId,
                      withTable: false,
                    },
                  }),
                ]
              },
            },
          ],
          null,
          false,
          3629035342
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }